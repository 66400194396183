export default [
  {
    key: "type",
    label: "Type",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "transaction_id",
    label: "Transaction ID",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "date",
    label: "Date",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "amount",
    label: "Amount",
    sortable: true,
    thClass: "text-center",
    tdClass: "pt-1 text-center",
    formatter: (value) => {
      return `$ ${value}`
    },
    visible: true,
  },
  {
    key: "status_id",
    label: "Status",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "user_name",
    label: "User",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "created_at",
    label: "Creation Date",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "updated_at",
    label: "Approved Date",
    tdClass: "pt-1",
    visible: true,
  },
  {
    key: "actions",
    label: "Action",
    tdClass: "pt-1",
    visible: true,
  },
]
