var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"register-payment-other-review",attrs:{"title":_vm.type == 1 ? 'View Client Payment' : 'View Lead Payment',"size":"xl","hide-footer":_vm.status != 2},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.triggerAction('disapprove')}}},[_vm._v(" Disapprove ")]),(_vm.canApprove)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.triggerAction('approve')}}},[_vm._v(" Approve ")]):_vm._e()]}}])},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h2',[_vm._v("Payments")])]},proxy:true}])},[_c('b-table',{staticClass:"position-relative font-small-3",attrs:{"fields":_vm.visibleFields,"items":_vm.payments,"small":"","primary-key":"id","empty-text":"No matching records found","select-mode":"multi","responsive":"sm","table-class":"text-nowrap","show-empty":"","sticky-header":"68vh"},scopedSlots:_vm._u([{key:"cell(option1)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.option1 == 1 ? "Client" : "Lead")+" ")]}},{key:"cell(second_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.second_name == "" || data.item.second_name == null ? "-" : data.item.second_name)+" ")]}},{key:"cell(option2)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.option2 == 1 && _vm.tab == "by-cash" ? "Cash" : data.item.option2 == 2 ? "Check" : data.item.option2 == 3 ? "" : data.item.option2 == 4 ? "Cashiers Check" : "Others( " + data.item.specifi + " )")+" ")]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" $ "+_vm._s(_vm._f("currency")(data.item.amount))+" ")]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.date))+" ")]}},{key:"cell(actions)",fn:function(data){return [(
            _vm.status == 2 &&
            (data.item.second_name == '' || data.item.second_name == null)
          )?_c('tabler-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"LinkIcon"},on:{"click":function($event){return _vm.openModalRegisterPaymentMatch(data.item)}}}):_vm._e(),(
            _vm.status == 2 &&
            data.item.second_name != '' &&
            data.item.second_name != null
          )?_c('tabler-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"LinkIcon"},on:{"click":function($event){return _vm.unMatchPayment(data.item)}}}):_vm._e()]}},{key:"custom-foot",fn:function(){return [_c('b-tr',[_c('b-td'),_c('b-td'),_c('b-td'),_c('b-td'),_c('b-td'),_c('b-td'),(_vm.type == 1)?_c('b-td'):_vm._e(),_c('b-td',[_vm._v("Total: ")]),_c('b-td',[_c('b-badge',[_vm._v(" $ "+_vm._s(_vm._f("currency")(_vm.sumPayments))+" ")])],1),(_vm.canMakeActions)?_c('b-td'):_vm._e()],1)]},proxy:true}])})],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h2',[_vm._v("Bank Deposit")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Transaction ID"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.bankDeposit.transaction_id),callback:function ($$v) {_vm.$set(_vm.bankDeposit, "transaction_id", $$v)},expression:"bankDeposit.transaction_id"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Date"}},[_c('b-form-input',{attrs:{"type":"date","disabled":""},model:{value:(_vm.bankDeposit.date),callback:function ($$v) {_vm.$set(_vm.bankDeposit, "date", $$v)},expression:"bankDeposit.date"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Amount"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"value":_vm.bankDeposit.amount,"disabled":""}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$ ',
              precision: 2,
            },false))],1)],1)],1)],1),(_vm.registerPaymentMatchPayment)?_c('register-payment-other-match-payment',{attrs:{"payment":_vm.selectedPayment},on:{"hidden":function($event){return _vm.closeModalRegisterPaymentMatch()},"onUpdateRelation":_vm.onUpdateRelation}}):_vm._e(),(_vm.registerPaymentApproveDisapprove)?_c('register-payment-other-approve-disapprove',{attrs:{"registerPaymentId":_vm.registerPaymentId,"action":_vm.action,"type":_vm.type},on:{"hidden":function($event){_vm.registerPaymentApproveDisapprove = false},"onDisapprove":function($event){return _vm.$emit('approvedOrDisapproved')},"onApprove":function($event){return _vm.$emit('approvedOrDisapproved')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }