<template>
  <b-modal
    :title="type == 1 ? 'View Client Payment' : 'View Lead Payment'"
    ref="register-payment-other-review"
    @hidden="$emit('hidden')"
    size="xl"
    :hide-footer="status != 2"
  >
    <b-form-group>
      <template #label>
        <h2>Payments</h2>
      </template>
      <b-table
        :fields="visibleFields"
        :items="payments"
        small
        class="position-relative font-small-3"
        primary-key="id"
        empty-text="No matching records found"
        select-mode="multi"
        responsive="sm"
        table-class="text-nowrap"
        show-empty
        sticky-header="68vh"
      >
        <template #cell(option1)="data">
          {{ data.item.option1 == 1 ? "Client" : "Lead" }}
        </template>
        <template #cell(second_name)="data">
          {{
            data.item.second_name == "" || data.item.second_name == null
              ? "-"
              : data.item.second_name
          }}
        </template>
        <template #cell(option2)="data">
          {{
            data.item.option2 == 1 && tab == "by-cash"
              ? "Cash"
              : data.item.option2 == 2
              ? "Check"
              : data.item.option2 == 3
              ? ""
              : data.item.option2 == 4
              ? "Cashiers Check"
              : "Others( " + data.item.specifi + " )"
          }}
        </template>
        <template #cell(amount)="data">
          $ {{ data.item.amount | currency }}
        </template>
        <template #cell(date)="data">
          {{ data.item.date | myGlobal }}
        </template>
        <template #cell(actions)="data">
          <tabler-icon
            v-if="
              status == 2 &&
              (data.item.second_name == '' || data.item.second_name == null)
            "
            icon="LinkIcon"
            class="cursor-pointer text-primary"
            @click="openModalRegisterPaymentMatch(data.item)"
          />
          <tabler-icon
            v-if="
              status == 2 &&
              data.item.second_name != '' &&
              data.item.second_name != null
            "
            @click="unMatchPayment(data.item)"
            icon="LinkIcon"
            class="cursor-pointer text-danger"
          />
        </template>
        <template #custom-foot>
          <b-tr>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td v-if="type == 1"></b-td>
            <b-td>Total: </b-td>
            <b-td>
              <b-badge> $ {{ sumPayments | currency }} </b-badge>
            </b-td>
            <b-td v-if="canMakeActions"></b-td>
          </b-tr>
        </template>
      </b-table>
    </b-form-group>
    <b-form-group>
      <template #label>
        <h2>Bank Deposit</h2>
      </template>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Transaction ID">
            <b-form-input v-model="bankDeposit.transaction_id" disabled />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="bankDeposit.date" disabled />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Amount">
            <money
              :value="bankDeposit.amount"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
              }"
              class="form-control"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <template #modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()"> Close </b-button>
      <b-button variant="danger" @click="triggerAction('disapprove')">
        Disapprove
      </b-button>
      <b-button
        variant="primary"
        v-if="canApprove"
        @click="triggerAction('approve')"
      >
        Approve
      </b-button>
    </template>
    <register-payment-other-match-payment
      v-if="registerPaymentMatchPayment"
      :payment="selectedPayment"
      @hidden="closeModalRegisterPaymentMatch()"
      @onUpdateRelation="onUpdateRelation"
    />
    <register-payment-other-approve-disapprove
      v-if="registerPaymentApproveDisapprove"
      :registerPaymentId="registerPaymentId"
      :action="action"
      :type="type"
      @hidden="registerPaymentApproveDisapprove = false"
      @onDisapprove="$emit('approvedOrDisapproved')"
      @onApprove="$emit('approvedOrDisapproved')"
    />
  </b-modal>
</template>
<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service.js";
import RegisterPaymentOtherMatchPayment from "@/views/administration/views/register-payment/components/others/RegisterPaymentOtherMatchPayment.vue";
import RegisterPaymentOtherApproveDisapprove from "@/views/administration/views/register-payment/components/others/RegisterPaymentOtherApproveDisapprove.vue";
import clientFields from "@/views/administration/views/register-payment/data/register-payments-other/view-payment/client.fields.js";
import leadFields from "@/views/administration/views/register-payment/data/register-payments-other/view-payment/lead.fields.js";
import { mapGetters } from "vuex";
export default {
  props: {
    registerPaymentId: {
      type: String,
      required: true,
    },
    status: {
      type: Number, // pending: 1, underview: 2, disapproved: 3, approved: 4
      required: true,
    },
    type: {
      type: Number, // type 1: client, type 2: lead
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  components: {
    RegisterPaymentOtherMatchPayment,
    RegisterPaymentOtherApproveDisapprove,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    canApprove() {
      let unmatchedPayments = this.payments.filter(
        (payment) => !payment.second_name
      );
      return !(unmatchedPayments.length > 0) || this.type == 1;
    },
    sumPayments() {
      return this.payments.reduce((a, b) => a + Number(b.amount), 0);
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    canMakeActions() {
      return (
        (this.isSupervisor || this.isCeo) && this.status == 2 && this.type == 2
      );
    },
  },
  data() {
    return {
      details: [],
      fields: this.type == 1 ? clientFields : leadFields,
      payments: [],
      bankDeposit: {
        payments: "",
      },
      registerPaymentMatchPayment: false,
      registerPaymentApproveDisapprove: false,
      action: "disapprove",
      selectedPayment: {},
    };
  },
  mounted() {
    this.toggleModal("register-payment-other-review");
    this.getPaymentMailById();
    if (this.canMakeActions) {
      this.fields.find((item) => item.key == "actions").visible = true;
    } else {
      this.fields.find((item) => item.key == "actions").visible = false;
    }
  },
  methods: {
    async getPaymentMailById() {
      try {
        const { data: details } =
          await RegisterPaymentService.getPaymentMailById({
            id: this.registerPaymentId,
          });
        if (details.length > 0) {
          this.bankDeposit = details[0];
          this.payments = JSON.parse(this.bankDeposit.payments);
        }
        // this.details = details;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openModalRegisterPaymentMatch(payment) {
      this.selectedPayment = payment;
      this.registerPaymentMatchPayment = true;
    },
    closeModalRegisterPaymentMatch() {
      this.registerPaymentMatchPayment = false;
    },
    onUpdateRelation() {
      this.getPaymentMailById();
      this.closeModalRegisterPaymentMatch();
    },
    async unMatchPayment(payment) {
      try {
        const response = await this.showConfirmSwal(
          "Are you sure you want to unmatch this payment?",
          null
        );
        if (!response.isConfirmed) return;
        await RegisterPaymentService.upairRelation({
          id: payment?.id,
        });
        this.getPaymentMailById();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async triggerAction(action) {
      this.registerPaymentApproveDisapprove = true;
      this.action = action;
    },
  },
};
</script>

<style>
</style>