<template>
  <b-modal
    title="Match Payments"
    ref="register-payment-other-match"
    @hidden="$emit('hidden')"
    size="lg"
    body-class="p-0"
    ok-title="Match"
    @ok.prevent="updateRelation()"
  > 
    <ValidationObserver ref="form">
    <b-table
      :fields="fields"
      :items="payments"
      class="position-relative font-small-3"
      primary-key="id"
      empty-text="No matching records found"
      select-mode="multi"
      responsive="sm"
      table-class="text-nowrap"
      show-empty
      sticky-header="68vh"
    >
      <template #cell(radio)="data">
        <ValidationProvider v-slot="{ errors }" name="radio" rules="required">
          <b-form-radio
            v-model="selectedPayment"
            name="radio"
            :value="data.item"
            :state="errors[0] ? false : null"
          />
        </ValidationProvider>
      </template>
    </b-table>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service.js";
export default {
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.toggleModal("register-payment-other-match");
    this.getAllPaymentPending();
  },
  data() {
    return {
      payments: [],
      name: "",
      selectedPayment: null,
      fields: [
        {
          key: "lead_name",
          label: "Lead Name",
        },
        {
          key: "type",
          label: "Payment",
          formatter: (value) => {
            return value == 1 ? "IP" : "Others";
          },
        },
        {
          key: "amount",
          label: "Amount",
          formatter: (value) => {
            return `$ ${value}`;
          },
        },
        {
          key: "created_at",
          label: "Date",
          formatter: (value) => {
            return this.$options.filters.myGlobalDay(value);
          },
        },
        {
          key: "radio",
          label: "",
        },
      ],
    };
  },
  methods: {
    async getAllPaymentPending() {
      try {
        const { data: payments } =
          await RegisterPaymentService.getAllPaymentPending({
            name: this.name,
          });
        this.payments = payments.filter((payment) => !payment.serial);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async updateRelation() {
      try {
        const validate = await this.$refs.form.validate();
        if (!validate) return;
        const swal = await this.showConfirmSwal(
          "Are you sure you want to match this payment?"
        );
        if (!swal.isConfirmed) return;
        await RegisterPaymentService.updateRelation({
          id: this.payment?.id,
          id_p: this.selectedPayment?.id,
          type: this.selectedPayment?.type,
        });
        this.$emit("onUpdateRelation", this.selectedPayment);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
</style>