export default [
    {
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "From",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
    },
    {
      type: "datepicker",
      margin: true,
      showLabel: true,
      label: "To",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      cols: 6,
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Type",
      model: null,
      options: [
        {
          id: 0,
          label: "All",
        },
        {
          id: 1,
          label: "Client",
        },
        {
          id: 2,
          label: "Lead",
        }
      ],
      reduce: "id",
      selectText: "label",
      cols: 12,
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "Status",
      model: null,
      options: [
        {
          id: 0,
          label: "All",
        },
        {
          id: 1,
          label: "Pending",
        },
        {
          id: 2,
          label: "Underview",
        },
        {
          id: 3,
          label: "Disapproved",
        },
        {
          id: 4,
          label: "Approved",
        }
      ],
      reduce: "id",
      selectText: "label",
      cols: 12,
    },
    {
      type: "select",
      margin: true,
      showLabel: true,
      label: "User",
      model: null,
      options: [
        {
          id: 0,
          user_name: "All",
        }
      ],
      reduce: "id",
      selectText: "user_name",
      cols: 12,
    },
  ]
  