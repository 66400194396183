export default [
    {
      key: "option1",
      label: "Type",
      visible: true,
    },
    {
      key: "lead",
      label: "Lead",
      visible: true,
    },
    {
      key: "second_name",
      label: "Name Match",
      visible: true,
    },
    {
      key: "subject",
      label: "Subject",
      visible: true,
    },
    {
      key: "option2",
      label: "Method Payment",
      visible: true,
    },
    {
      key: "serial",
      label: "Serial",
      visible: true,
    },
    {
      key: "date",
      label: "Date",
      visible: true,
    },
    {
      key: "amount",
      label: "Amount",
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      visible: true,
    },
  ]