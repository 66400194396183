export default [
    {
      key: "option1",
      label: "Type",
      visible: true,
    },
    {
      key: "account",
      label: "Account",
      visible: true,
    },
    {
      key: "program",
      label: "Program",
      visible: true,
    },
    {
      key: "advisor",
      label: "Advisor",
      visible: true,
    },
    {
      key: "subject",
      label: "Subject",
      visible: true,
    },
    {
      key: "option2",
      label: "Method Payment",
      visible: true,
    },
    {
      key: "serial",
      label: "Serial",
      visible: true,
    },
    {
      key: "date",
      label: "Date",
      visible: true,
    },
    {
      key: "amount",
      label: "Amount",
      formatter: (value) => {
        return `$ ${value}`
      },
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      visible: true,
    },
  ]