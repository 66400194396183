<template>
  <b-modal
    title="Tracking"
    ref="register-payment-other-tracking"
    @hidden="$emit('hidden')"
    size="lg"
    body-class="p-0"
    hide-footer
  >
    <b-table
      :fields="fields"
      :items="details"
      small
      class="position-relative font-small-3"
      primary-key="id"
      empty-text="No matching records found"
      select-mode="multi"
      responsive="sm"
      table-class="text-nowrap"
      show-empty
      sticky-header="68vh"
    >
      <template #cell(created_at)="data">
        {{ data.item.created_at | myGlobalDay }}
      </template>
      <template #cell(type)="data">
        <span
          :class="
            data.item.type == 2
              ? 'text-primary'
              : data.item.type == 4
              ? 'text-success'
              : 'text-danger'
          "
        >
          {{
            data.item.type == 2
              ? "Send"
              : data.item.type == 3
              ? "Dissaprove"
              : "Approve"
          }}
        </span>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service.js";
export default {
  props: {
    registerPaymentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      details: [],
      fields: [
        {
          key: "created_at",
          label: "Date",
          visible: true,
        },
        {
          key: "user_name",
          label: "User",
          visible: true,
        },
        {
          key: "type",
          label: "Type",
          visible: true,
        },
        {
          key: "observation",
          label: "Observation",
          visible: true,
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("register-payment-other-tracking");
    this.getTrackingRegisterById();
  },
  methods: {
    async getTrackingRegisterById() {
      try {
        const { data: details } =
          await RegisterPaymentService.getTrackingRegisterById({
            id: this.registerPaymentId,
          });
        this.details = details;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
</style>