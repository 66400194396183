<template>
  <b-modal
    :title="action == 'approve' ? 'Approve Payment' : 'Disapprove Payment'"
    ref="register-payment-other-approve-disapprove"
    @hidden="$emit('hidden')"
    size="lg"
    @ok.prevent="
      action == 'approve'
        ? approveLeadPaymentMail()
        : disapprovePaymentMailOfRegisterClient()
    "
    :ok-title="action == 'approve' ? 'Approve' : 'Disapprove'"
  >
    <ValidationObserver ref="form">
      <b-form-group label="Observation">
        <ValidationProvider
          v-slot="{ errors }"
          name="observation"
          rules="required"
        >
          <b-form-textarea
            id="textarea"
            v-model="observation"
            name="observation"
            placeholder="Enter something..."
            rows="3"
            :state="errors[0] ? false : null"
            max-rows="6"
          ></b-form-textarea>
        </ValidationProvider>
      </b-form-group>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    registerPaymentId: {
      type: String,
      required: true,
    },
    type: {
      type: Number, // type 1: client, type 2: lead
      required: true,
    },
  },
  data() {
    return {
      observation: null,
    };
  },
  mounted() {
    this.toggleModal("register-payment-other-approve-disapprove");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async disapprovePaymentMailOfRegisterClient() {
      try {
        const valid = await this.$refs.form.validate();
        if (!valid) return;
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        await RegisterPaymentService.disapprovePaymentMailOfRegisterClient({
          id: this.registerPaymentId,
          observation: this.observation,
          user_id: this.currentUser.user_id,
        });
        this.$emit("onDisapprove");
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async approveLeadPaymentMail() {
      try {
        const valid = await this.$refs.form.validate();
        if (!valid) return;
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        if (this.type == 1) {
          await RegisterPaymentService.approveClientPaymentMail({
            id: this.registerPaymentId,
            observation: this.observation,
            user_id: this.currentUser.user_id,
          });
        } else {
          await RegisterPaymentService.approveLeadPaymentMail({
            id: this.registerPaymentId,
            observation: this.observation,
            user_id: this.currentUser.user_id,
          });
        }
        this.$emit("onApprove");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>